import type * as maplibregl from 'maplibre-gl';
import CityOS__Takamatsu from '../cityos/cityos_takamatsu';
import { Facility } from '../App/Map';

const addStyles = (map: maplibregl.Map, cityOS: CityOS__Takamatsu, reversed_facilities: Facility[], shelterData: any) => {

  // レイヤーの優先順位を保つために、逆順に追加する
  const facilities = [...reversed_facilities].reverse();
  for (let i = 0; i < facilities.length; i++) {
    const facility = facilities[i];
    let sourceId;

    // fiwareデータ
    if (facility.type === 'fiware') {
      for (let j = 0; j < facility.sources.length; j++) {

        const source = facility.sources[j];
        const evacuationShelter = ['==', ['get', 'evacuationSpace'], "○"];
        const minZoom = facility.id === "shelter" ? 12 : 10;

        sourceId = cityOS.addLiveDataSet(source, {
          featureFilter: (feature) => {
            feature.properties ||= {};
            return feature;
          }
        }, facility.id === "shelter" && shelterData);

        /* ------------- *
         * 危険度に応じてicon-imageを切り替える条件
         * ------------- */

        // 冠水あり(冠水状況)
        const flooding = [
          'all',
          ['==', ['get', 'type'], 'FloodSituation'],
          ['==', ["to-number",['get', 'status']], 1]
        ];
        // 冠水なし(冠水状況)
        const noFlooding = [
          'all',
          ['==', ['get', 'type'], 'FloodSituation'],
          ['==', ["to-number",['get', 'status']], 0]
        ];


        if(!map.getLayer(source)) {
          map.addLayer({
            id: source,
            type: 'symbol',
            source: sourceId,
            minzoom: minZoom,
            layout: {
              'icon-image': [
                'case',
                ['boolean', evacuationShelter, true], `takamatsu-shelter-evacuationSpace`,
                ['boolean', noFlooding, true], `takamatsu-${facility.id}-noFlooding`,
                ['boolean', flooding, true], `takamatsu-${facility.id}-flooding`,
                `takamatsu-${facility.id}`
              ],
              'icon-allow-overlap': true,
              'icon-size': 0.5,
              'visibility': 'visible',
            },
          });
        }
      }

    // Fiware以外のデータ
    } else {

      if(!map.getSource(facility.id)) {
        map.addSource(facility.id, {
          type: 'geojson',
          data: facility.geojson
        });
      }

      // 避難所かどうか
      let minZoom = 15;
      let facilityVisibility: "visible" | "none" | undefined = 'none' ;

      if (facility.id === "chikadou_custom_point"){
        minZoom = 10;
        facilityVisibility = 'visible';
      }

      if(!map.getLayer(facility.id)) {
        map.addLayer({
          id: facility.id,
          type: 'symbol',
          source: facility.id,
          minzoom: minZoom,
          layout: {
            'icon-image': `takamatsu-${facility.id}`,
            'icon-allow-overlap': true,
            'icon-size': 0.5,
            'visibility': facilityVisibility,
          },
        });
      }

    }
  }
}

export default addStyles;
