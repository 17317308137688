
const sortDataByLabel = (data: Array<{key:string, value: any}>, sortLabels:Object) => {
  const sortedOtherTableData = [];
  for (const key in sortLabels) {
    // @ts-ignore
    const findData = data.find((data) => data.key === sortLabels[key]);
    if (findData) {
      sortedOtherTableData.push(findData);
    }
  }

  return sortedOtherTableData;
};

export default sortDataByLabel;
