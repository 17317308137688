import type * as maplibregl from 'maplibre-gl';

const negativeCityMaskStyle = (map: maplibregl.Map) => {

  map.addSource('negative-city-mask', {
    type: 'vector',
    url: 'https://tileserver.geolonia.com/takamatsu_negative_mask/tiles.json?key=8a9a6e7bf2ce43e188100aca3d78489a',
  })
  map.addLayer({
    id: 'negative-city-mask-layer',
    source: 'negative-city-mask',
    'source-layer': 'negativecitymask',
    type: 'fill',
    paint: {
      'fill-color': '#0079C4',
      'fill-opacity': .3,
    }
  }, 'poi')
  map.addLayer({
    id: 'negative-city-mask-layer-border',
    source: 'negative-city-mask',
    'source-layer': 'negativecitymask',
    type: 'line',
    paint: {
      'line-color': '#0079C4',
      'line-opacity': 0.5,
      'line-width': 2,
    }
  }, 'poi')

}

export default negativeCityMaskStyle;
