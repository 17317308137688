import * as maplibregl from 'maplibre-gl';
import config from '../config.json'

const beforeLayerId = config.data[config.data.length - 1].id

export const baseRoute = [
  {
    id: 'ken-shidou_polyline',
    title: '県道・市道',
    type: 'line',
    url: './baseroute/ken-shidou_polyline.geojson',
    color: '#00A560'
  },
  {
    id: 'kokudou_gsi',
    title: '国道（国土地理院）',
    color: '#00A560',
    category: "gsi",
    rdCtg: 0
  },
  {
    id: 'kinkyuuyusoudouro_line',
    title: '緊急輸送道路',
    type: 'line',
    url: './baseroute/kinkyuuyusoudouro_line.geojson',
    color: '#00A560'
  },
  {
    id: 'shuyoudouro_polyline',
    title: '主要道路',
    type: 'line',
    url: './baseroute/shuyoudouro_polyline.geojson',
    color: '#00A560',
    opacity: 0.5,
    dasharray: [6, 3],
    textSize: 14
  },
]

export const addBaseRoute = (map: maplibregl.Map) => {

  for (let i = 0; i < baseRoute.length; i++) {
    const route = baseRoute[i];

    map.addSource(route.id, {
      type: 'geojson',
      data: route.url
    });

    let customStyle = {}

    if (route.category === "gsi") {

      customStyle = {
        source: "gsi-japan",
        "source-layer": "road",
        "filter": [
          "==",
          "rdCtg",
          route.rdCtg
        ],
      }

    } else {

      customStyle = {
        source: route.id,
      }
    }

    const templateLine = {
      id: route.id,
      type: 'line',
      minzoom: 12,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
        'visibility': 'none',
      },
      paint: {
        'line-color': route.color,
        'line-width': 3,
        'line-opacity': route.opacity ? route.opacity : 0.2,
        'line-dasharray': route.dasharray ? route.dasharray : [3, 6],
      },
    }

    const symbolTemplate = {
      id: `${route.id}-symbol`,
      type: 'symbol',
      source: route.id,
      minzoom: 16,
      layout: {
        'text-field': route.title,
        'symbol-placement': 'line',
        'symbol-spacing': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16,
          300,
          20,
          600
        ],
        "text-font": [
          "Noto Sans Regular"
        ],
        "text-size": route.textSize ? route.textSize : 12,
        'visibility': 'none',
      },
      paint: {
        "text-halo-width": 2,
        "text-halo-color": "rgba(255,255,255,0.8)",
        "text-color": route.color
      }
    }

    // @ts-ignore
    map.addLayer({
      ...symbolTemplate,
      ...customStyle
    }, beforeLayerId);

    // @ts-ignore
    map.addLayer({
      ...templateLine,
      ...customStyle
    }, `${route.id}-symbol`);
  }




}
