import './Popup.scss';
import { PopupHazardContentProps } from './Popup';
import PopupContentHazard from './PopupContentHazard';
import PopupContentFacility from './PopupContentFacility';

const PopupContent: React.FC<PopupHazardContentProps> = (props) => {

  const {
    popupCategory,
  } = props

  return <div className="popup-container">
    {popupCategory === 'hazard' && <PopupContentHazard {...props}/>}
    {popupCategory && popupCategory !== 'hazard' && <PopupContentFacility {...props}/>}
  </div>
}

export default PopupContent;
