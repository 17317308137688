import './Popup.scss';
import type geolonia from '@geolonia/embed'
import type * as maplibregl from 'maplibre-gl';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import PopupContent from './PopupContent';
import { Facility, openDataProps } from './Map';

export type PopupHazardContentProps = {
  hazard: { [layerName: string]: GeoJSON.GeoJsonProperties[] } | null,
  shelter: openDataProps | null,
  popupCategory: string | null,
  /**
   * undefined: 未取得
   * null: 取得済みだが、値がない
   * string: 取得済みで、値がある
   */
  altitude: string | null | undefined,
  isFetchingPopupData: boolean,
  /**
   * Facilities[]: 取得済み
   * undefined: 未取得
   */
  facilities: Facility[] | undefined,
  map: maplibregl.Map | null,
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setPopupCategory: React.Dispatch<React.SetStateAction<string | null>>,
  setShelter: React.Dispatch<React.SetStateAction<openDataProps | null>>
  popupLatLng: maplibregl.LngLatLike | null,
  setPopupLatLng: React.Dispatch<React.SetStateAction<maplibregl.LngLatLike | null>>,
  popupObj: maplibregl.Popup | null
  shelterData: GeoJSON.FeatureCollection | null  // 表示用の避難所データ
}

const markerHeight = 26.5;

export const usePopup = (
  map: maplibregl.Map | null,
  lngLat: maplibregl.LngLatLike | null,
  isPopupOpen: boolean,
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>,
  isFetchingPopupData: boolean,
  popupCategory: string | null,
) => {

  const [popup, setPopup] = useState<maplibregl.Popup | null>(null)

  useEffect(() => {
    if (isPopupOpen) {

      if (!map || !lngLat || popup || !popupCategory) {
        return
      }

      const category = popupCategory === 'hazard' ? 'hazard' : `facility ${popupCategory}`

      // @ts-ignore
      const newPopup =new geolonia.Popup({
        offset: {'bottom': [0, -markerHeight]},
        closeButton: true,
        anchor: 'bottom',
        className: `takamatsu-popup ${category}`,
      }) as maplibregl.Popup

      newPopup
        .setLngLat(lngLat)
        .setHTML('')
        .setMaxWidth("340px")
        .on('close', () => {
          setIsPopupOpen(false);
        })
        .addTo(map);

      setPopup(newPopup)

    } else {
      if (popup) {
        popup.remove()
      }
      setPopup(null)
    }
  }, [isPopupOpen, lngLat, map, popup, popupCategory, setIsPopupOpen])

  // ポップアップ表示時に、ポップアップの中心が地図の中心になるようにする
  useEffect(() => {

    if (isPopupOpen) {

      if (!popup || !popup.getElement() || !map || map?.getZoom() < 10 || isFetchingPopupData) {
        return
      }
      const { top: mapTop, left: mapLeft } = map.getContainer().getBoundingClientRect()
      const { left, bottom, width, height } = popup.getElement().getBoundingClientRect()
      const nextCenterPoint: maplibregl.LngLatLike = [left - mapLeft + width / 2, bottom - mapTop - height / 2]
      const targetCenter = map.unproject(nextCenterPoint)

      map.flyTo({
        center: targetCenter,
      })

    }

  // ポップアップのデータを取得したタイミングで、再度ポップアップの位置を調整するために、isFetchingPopupData を依存に含める
  },[isPopupOpen, isFetchingPopupData, map, popup]);

  const popupElement = popup && popup.getElement() && popup.getElement().querySelector('.maplibregl-popup-content')

  const Popup: React.FC<PopupHazardContentProps> = (props) => {
    // popupElement ? ReactDOM.createPortal(<PopupContent {...props} popupObj={popup} />, popupElement) : null

    if(popupElement) {
      return ReactDOM.createPortal(<PopupContent {...props} popupObj={popup} />, popupElement)
    } else {
      return null
    }

  }

  return {
    Popup
  }
}
