import { useState } from 'react';
import './IosA2hsPopup.scss';
import UAParser from 'ua-parser-js';

const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches

const IosA2hsPopup = () => {

  let initialPopupState = false;
  const closePopupStateAtCookie = document.cookie.split('; ').find(row => row.startsWith('takamatsu-safety-map-ios-popup-closed'))

  if (closePopupStateAtCookie) {
    initialPopupState = closePopupStateAtCookie.split('=')[1] === 'true' ? true : false;
  }

  const [closePopup, setClosePopup] = useState(initialPopupState);

  const parser = new UAParser();
  const os = parser.getOS().name;
  const browser = parser.getBrowser().name;

  const saveCloseState = () => {
    setClosePopup(true);

    const date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = "takamatsu-safety-map-ios-popup-closed=true;" + expires + ";path=/";
  }

  return (
    <>
      {!closePopup && os ==="iOS" && !isInStandaloneMode() &&
        <>
          <div className="ios-a2hs-popover">
            <button className="ios-a2hs-popover__close-button" onClick={saveCloseState}>
              <svg className="ios-a2hs-popover__close-icon" viewBox="0 0 24 24">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </button>
            
            <div className="ios-a2hs-popover__content">
            <div className="ios-a2hs-popover__add-logo"><img src="./logo.svg" alt="logo"  className="ios-a2hs-popover__add-logo-img"/></div>
            {browser === 'Mobile Safari' ?
              <p>このアプリをホーム画面に追加できます。下の矢印アイコンをタップして<span>「ホーム画面に追加」</span>を選択して下さい。</p>
              :
              <p>このアプリをホーム画面に追加するには、Safari を使用して下さい。</p>
            }
            </div>
          </div>
        </>
      }
    </>
  );
}

export default IosA2hsPopup;
