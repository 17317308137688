import React, { useRef, useState, useEffect } from "react";
import addMarker from '../utils/addMarker'
import { usePopup } from "./Popup";
import './Map.scss'
import { takamatsuHazardReverseGeocode } from "../utils/reverse-geocode";
import { addCustomGeolocateControl, geolocateButtonStyle, geolocateInnerButtonRendered, popupForGeolocateButton } from '../utils/geolocateControl'
import addStyles from '../utils/addStyles'
import negativeCityMaskStyle from '../utils/negativeCityMaskStyle'
import { addBaseRoute, baseRoute } from '../utils/addBaseRoute'
import CityOS__Takamatsu from '../cityos/cityos_takamatsu';
import * as maplibregl from 'maplibre-gl';
import config from '../config.json';
import mapStyle from '../style.json';

export type openDataProps = {
  name: string,
  address: string,
  elevation: string,
  telephoneNumber: string,
  capacity: string,
  sedimentDisaster: string,
  flood_L2: string,
  stormSurge: string,
  [key: string]: string,
}

export type lngLatProps = {
  lat: number;
  lng: number;
};

export type Facility = typeof config['data'][0] & {
  geojson: GeoJSON.FeatureCollection,
}

const CSS: React.CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative',
}

const popupMinZoom = 10

const hideGuidePanel = (map: maplibregl.Map) => {

  // 建物が見えるまでパネルを表示する
  const currentZoom = map.getZoom()
  if (currentZoom < popupMinZoom) {
    return
  }

  const guidePanel = document.querySelector('#guide-panel-check') as HTMLInputElement
  if (guidePanel) {
    guidePanel.checked = false
  }
}

const layerIds = config.data.map((data) => {
  if (data.type === 'fiware') {
    return data.sources
  } else {
    return data.id
  }
}).flat()

const fetchAltitude = async (lat: number, lng: number) => {
  const url = `https://api-vt.geolonia.com/api/altitude?lat=${lat}&lng=${lng}`
  const response = await fetch(url)
  const data = await response.json()
  return data
}

const Content = () => {
  const mapNode = useRef<HTMLDivElement>(null);
  const zoomInAlertNode = useRef<HTMLDivElement>(null);
  const [mapObject, setMapObject] = useState<maplibregl.Map | null>(null)
  const [popupLatLng, setPopupLatLng] = useState<maplibregl.LngLatLike | null>(null)
  const [currentMarker, setCurrentMarker] = useState<maplibregl.Marker | null>(null)
  const [hazard, setHazard] = useState<{ [layerName: string]: GeoJSON.GeoJsonProperties[] } | null>(null)
  const [shelter, setShelter] = useState<openDataProps | null>(null)
  const [popupCategory, setPopupCategory] = useState<string | null>(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isFetchingPopupData, setIsFetchingPopupData] = useState(false)
  const [facilities, setFacilities] = useState<Facility[] | undefined>(undefined)
  const [altitude, setAltitude] = useState<string | null | undefined>(undefined)
  const [cityOS, setCityOS] = useState<CityOS__Takamatsu | null>(null)
  const [shelterOpenData, setShelterOpenData] = useState<GeoJSON.FeatureCollection | null>(null);

  // イベントリスナー内での state 更新用
  const markerRef: React.MutableRefObject<maplibregl.Marker | null> = useRef(null);
  markerRef.current = currentMarker;

  const isPopupOpenRef: React.MutableRefObject<boolean> = useRef(false);
  isPopupOpenRef.current = isPopupOpen;

  const { Popup } = usePopup(mapObject, popupLatLng, isPopupOpen, setIsPopupOpen, isFetchingPopupData, popupCategory)

  const { geolonia } = window;

  useEffect(() => {
    // Only once render the map.
    if (!mapNode.current || mapObject) {
      return
    }

    //@ts-ignore
    const map: maplibregl.Map = new window.geolonia.Map({
      container: mapNode.current,
      //@ts-ignore
      style: mapStyle,
      fitBoundsOptions: { padding: 50 },
      center: [134.046303, 34.342841],
      zoom: 9,
      // 意図せず傾き・回転を変更してしまうことを防ぐ
      maxPitch: 0,
      // @ts-ignore
      maxRotate: 0,
      minZoom: 9,
      localIdeographFontFamily: 'sans-serif'
    });

    const onMapLoad = async () => {

      const geolocateControl = addCustomGeolocateControl(map);

      setMapObject(map)
      // 高松市外のマスクスタイルを追加
      negativeCityMaskStyle(map)
      // ベース道路データを追加
      addBaseRoute(map)

      const addPopup = async (lngLat: lngLatProps) => {

        const point = map.project(lngLat);
        const facilityLayers = map.queryRenderedFeatures(point, { layers: layerIds });

        // 高松市外のマスクレイヤーを取得
        const negativeMaskFeatures = map.queryRenderedFeatures(point, { layers: ['negative-city-mask-layer'] });
        // 市外マスクをクリックした場合は処理しない
        if (negativeMaskFeatures.length > 0 && facilityLayers.length === 0) {
          return
        }

        // 建物が見えるまで災害情報ポップアップを表示しない
        const currentZoom = map.getZoom()
        if (currentZoom < popupMinZoom) {
          return
        }

        if (!isPopupOpenRef.current) {

          setPopupLatLng(lngLat)
          setIsPopupOpen(true)
          setIsFetchingPopupData(true)

          // 施設レイヤーをクリックした時
          if (facilityLayers.length > 0) {

            setPopupCategory(facilityLayers[0].layer.id)
            setShelter(facilityLayers[0].properties as openDataProps)

            setIsFetchingPopupData(false)

          // ハザードレイヤーをクリックした時
          } else {

            const marker = addMarker(lngLat, map, markerRef.current)
            setCurrentMarker(marker)

            // 建物が確認できるズームレベルに移動してからポップアップを表示する
            map.flyTo({
              center: lngLat,
              zoom: 15,
              speed: 2
            })

            const { lat, lng } = lngLat
            setPopupCategory('hazard')

            await Promise.all([
              Promise.all([
                new Promise(resolve => map.once('moveend', resolve)),
                takamatsuHazardReverseGeocode(lng, lat),
              ])
                .then(([, fetchedHazard]) => {
                  setIsFetchingPopupData(false)
                  setHazard(fetchedHazard)
                }),
              fetchAltitude(lat, lng)
                .then((altitudeData) => setAltitude(altitudeData.altitude)),
            ]);
          }

        } else {
          setAltitude(undefined)
          setPopupCategory(null)
          setIsPopupOpen(false)
        }
      }

      map.on('click', (e) => addPopup(e.lngLat));

      // ガイドパネルを非表示にする
      map.on('mouseup', (e) => {
        hideGuidePanel(map)
      });
      map.on('touchend', (e) => {
        hideGuidePanel(map)
      });
      map.on('dragend', () => {
        setIsPopupOpen(false)
      });

      map.on('zoom', () => {
        // 「災害情報を表示するには…」メッセージの表示/非表示
        if (map.getZoom() > popupMinZoom) {
          zoomInAlertNode.current?.classList.add('hide')
        } else {
          zoomInAlertNode.current?.classList.remove('hide')
        }
      });

      await geolocateInnerButtonRendered(geolocateControl)

      // GeolocateControl の現在地取得時のスタイルを追加
      geolocateButtonStyle(geolocateControl);

      // GeolocateControl で現在地を取得した時にポップアップを表示
      popupForGeolocateButton(
        geolocateControl,
        map,
        setIsPopupOpen,
        addPopup
      )

    }

    const orienteationchangeHandler = () => {
      map.resize()
    }

    // attach
    map.on('load', onMapLoad)

    window.addEventListener('orientationchange', orienteationchangeHandler)

    return () => {
      // detach to prevent memory leak
      window.removeEventListener('orientationchange', orienteationchangeHandler)
      map.off('load', onMapLoad)
    }

  }, [facilities, geolonia.Map, mapObject])

  // 施設のマーカーのスタイルを追加
  useEffect(() => {
    if(mapObject && !cityOS) {
      const takamatsu = new CityOS__Takamatsu(mapObject);
      setCityOS(takamatsu);
    }
    if(mapObject && facilities && cityOS) {
      // addStyles は React の state として管理される facilities に依存する関数だが、state 更新と連動する必要はないため、最初に呼ばれたときだけ maplibre のスタイルを追加し、以降は素通りする実装になっている
      // また、load イベントではなく useEffect で実行されているのは、facilities は map の描画完了を待たず非同期的に取得されるため
      addStyles(mapObject, cityOS, facilities, shelterOpenData);
    }
  }, [cityOS, facilities, mapObject, shelterOpenData])

  // ポップアップの閉じるボタンを押したときにマーカーを削除する
  useEffect(() => {

    // ポップアップ開いた時に避難所マーカーを表示する
    if (isPopupOpen && mapObject) {

      for (let i = 0; i < baseRoute.length; i++) {
        const route = baseRoute[i];
        mapObject.setLayoutProperty(route.id, 'visibility', 'visible')
        mapObject.setLayoutProperty(`${route.id}-symbol`, 'visibility', 'visible')

      }

      for (let i = 0; i < layerIds.length; i++) {
        const layerId = layerIds[i];
        mapObject.setLayoutProperty(layerId, 'visibility', 'visible')
      }

    }

    setAltitude(undefined)
    setPopupCategory(null)

  }, [isPopupOpen, mapObject])


  // 近くの施設一覧を取得
  useEffect(() => {
    const fetchData = async () => {

      const sourceFeaturesMap = new Map<string, GeoJSON.Feature[]>()
      // 施設データの取得(fiware以外のデータ)
      const sources = config.data
        .filter(data_item => data_item.type !== 'fiware')
        .map(data_item => data_item.sources)
        .flat()

      await Promise.all(sources.map(async source => {
        const response = await fetch(source);
        const { features } = await response.json() as GeoJSON.FeatureCollection;
        sourceFeaturesMap.set(source, features);
      }))

      const shelterData: GeoJSON.FeatureCollection = await fetch("https://opendata.takamatsu-fact.com/evacuation_space/data.geojson")
                            .then((data) => data.json());
      setShelterOpenData(shelterData);

      // fiwareデータも含むデータオブジェクト
      const nextFacilities: Facility[] = config.data.map(data_item => {
        const merged_geojson: GeoJSON.FeatureCollection = { type: 'FeatureCollection', features: [] }
        for (const source of data_item.sources) {
          const features = sourceFeaturesMap.get(source)
          if(features) {
            merged_geojson.features.push(...features)
          }
        }
        return { ...data_item, geojson: merged_geojson }
      })

      setFacilities(nextFacilities);
    };
    fetchData();

  }, [])


  return (
    <div style={CSS}>
      <div
        ref={zoomInAlertNode}
        className="zoomInAlert"
      >
        災害情報を表示するには、ズームして<br />地図をタップして下さい
      </div>
      <div
        ref={mapNode}
        style={CSS}
        data-geolocate-control="off"
        data-navigation-control="off"
        data-marker="off"
        data-gesture-handling="off"
      ></div>
      <Popup
        hazard={hazard}
        shelter={shelter}
        popupCategory={popupCategory}
        isFetchingPopupData={isFetchingPopupData}
        facilities={facilities}
        map={mapObject}
        altitude={altitude}
        setIsPopupOpen={setIsPopupOpen}
        setPopupCategory={setPopupCategory}
        setShelter={setShelter}
        popupLatLng={popupLatLng}
        setPopupLatLng={setPopupLatLng}
        popupObj={null}
        shelterData={shelterOpenData}
      />
    </div>
  );
};

export default Content;
