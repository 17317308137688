import { Fragment } from 'react';

export const text2BreakTag = (text: string) => {
  return text.split(/(\n)/).map((item, index: number) => {
    return (
      <Fragment key={index}>
        {item.match(/\n/) ? <br /> : item}
      </Fragment>
    );
  });
}
