import React from 'react';
import ReactLoading from 'react-loading';

export const Loading = () => {
  return (
    <ReactLoading
      className="popup-loading"
      type={"spin"}
      color={"#999999"}
      height={'15%'}
      width={'15%'}
    ></ReactLoading>
  );
};
