import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";

import Header from "./Header";
import Map from './App/Map'
import IosA2hsPopup from "./IosA2hsPopup";

const App = () => {

  return (
    <div className="app">
      <Header />
      <div className="app-body">
        <Routes>
          <Route path="/" element={<Map/>} />
        </Routes>
        <IosA2hsPopup />
      </div>
    </div>
  );
}

export default App;
