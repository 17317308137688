import * as maplibregl from 'maplibre-gl';
import { lngLatProps } from "../App/Map";

const addMarker = (lngLat:lngLatProps , map: maplibregl.Map, currentMarker: maplibregl.Marker | null) => {

  const el = document.createElement('div');
  el.className = 'user-position-marker';

  if (currentMarker) {
    currentMarker.remove()
  }

  currentMarker = new maplibregl.Marker(el)
    .setLngLat(lngLat)
    .addTo(map);

  return currentMarker;
}

export default addMarker;
