import type * as maplibregl from 'maplibre-gl';

export const addCustomGeolocateControl = (map: maplibregl.Map) => {
  // @ts-ignore
  const geolocateControl = new window.maplibregl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
  })

  map.addControl(geolocateControl, 'bottom-right');

  return geolocateControl;
}

/**
 * コントロール内部のボタンは非同期でレンダリングされるため、それを検知する
 * @param geolocateControl
 * @returns HTMLElement
 */
export const geolocateInnerButtonRendered = (geolocateControl: maplibregl.GeolocateControl): Promise<HTMLButtonElement> => {
  const geolocateContainer = geolocateControl._container
  const innerButton = geolocateContainer.querySelector('.maplibregl-ctrl-geolocate')
  if(innerButton && innerButton instanceof HTMLButtonElement) {
    return Promise.resolve(innerButton)
  } else {
    return new Promise((resolve) => {
      const observer = new MutationObserver((_mutations, observer) => {
        const innerButton = geolocateContainer.querySelector('.maplibregl-ctrl-geolocate')
        if(innerButton && innerButton instanceof HTMLButtonElement) {
          observer.disconnect()
          resolve(innerButton)
        }
      })
      observer.observe(geolocateContainer, { childList: true })
    })
  }
}

export const geolocateButtonStyle = (geolocateControl: maplibregl.GeolocateControl) => {

  const geolocateContainer = geolocateControl._container
  const geolocateControlDom = geolocateContainer.querySelector('.maplibregl-ctrl-geolocate') as HTMLButtonElement

  geolocateControlDom.addEventListener('click', () => {
    geolocateControlDom.classList.add('is-geolocating')
  })

  geolocateControl.on('geolocate', () => {
    geolocateControlDom.classList.remove('is-geolocating')
  });

  geolocateControl.on('error', () => {
    geolocateControlDom.classList.add('is-geolocate-error')
  });
}

export const popupForGeolocateButton = (
  geolocateControl: maplibregl.GeolocateControl,
  map: maplibregl.Map,
  setIsPopupOpen: any,
  addPopup: any,
) => {

  const geolocateContainer = geolocateControl._container
  const geolocateControlDom = geolocateContainer.querySelector('.maplibregl-ctrl-geolocate') as HTMLButtonElement

  geolocateControlDom.addEventListener('click', () => {
    setIsPopupOpen(false)
  })

  geolocateControl.on('geolocate', (e: any) => {

    // popup の中心表示のための移動で、再度呼び出されて無限ループになるのを防ぐため、once で呼び出す
    map.once('moveend', async () => {

      const lngLat = {
        lat: e.coords.latitude,
        lng: e.coords.longitude,
      }
      addPopup(lngLat)
    })
  });

}
