import './Popup.scss';
import { PopupHazardContentProps } from './Popup';
import { Loading } from './Loading';
import { roundNumber } from '../utils/roundNumber';
import { getDurationText } from '../utils/getDurationText';
import { text2BreakTag } from '../utils/text2BreakTag';
import { Facility } from './Map';
import { useEffect, useMemo, useState } from 'react';
import sortByDistance from '../utils/sortByDistance';

type IconInfoProps = {
  iconTitle?: string,
  icon?: string,
  color?: string
}

const hazardCategory = {
  '急傾斜地崩壊危険区域': {
    iconTitle: '土砂災害',
    icon: 'icons/icon-landslide.svg',
    color: '#B08C0A'
  },
  '地すべり防止区域': {
    iconTitle: '土砂災害',
    icon: 'icons/icon-landslide.svg',
    color: '#B08C0A'
  },
  '土石流危険渓流区域': {
    iconTitle: '土砂災害',
    icon: 'icons/icon-landslide.svg',
    color: '#B08C0A'
  },
  '土石流危険渓流流域': {
    iconTitle: '土砂災害',
    icon: 'icons/icon-landslide.svg',
    color: '#B08C0A'
  },
  '土砂災害警戒区域': {
    iconTitle: '土砂災害',
    icon: 'icons/icon-landslide.svg',
    color: '#B08C0A'
  },
  '洪水浸水想定区域図（想定最大規模）': {
    iconTitle: '洪水',
    icon: 'icons/icon-flood.svg',
    color: '#3278BE'
  },
  '洪水浸水想定区域図（氾濫流、家屋倒壊）': {
    iconTitle: '洪水',
    icon: 'icons/icon-flood.svg',
    color: '#3278BE'
  },
  '洪水浸水想定区域図（河岸侵食、家屋倒壊）': {
    iconTitle: '洪水',
    icon: 'icons/icon-flood.svg',
    color: '#3278BE'
  },
  '高潮浸水想定区域図（想定最大規模）': {
    iconTitle: '高潮',
    icon: 'icons/icon-tsunami.svg',
    color: '#455DB7'
  }
}

const _isNaN = (value: number | string | null) => {
  return Number.isNaN(Number(value))
}

const PopupContentHazard: React.FC<PopupHazardContentProps> = (props) => {

  const {
    hazard,
    isFetchingPopupData,
    facilities,
    map,
    altitude,
    setIsPopupOpen,
    setPopupCategory,
    setShelter,
    popupLatLng,
    setPopupLatLng,
    shelterData
  } = props

  const innerContents = [];

  const [newFeatures, setNewFeatures] = useState<any[]>([]);

  useEffect(() => {
    if(map && map.getSource('EvacuationShelter')) {
      const feature = [
        ...(map.getSource('EvacuationShelter') as any)._data.features,
        ...(map.getSource('TemporaryEvacuationShelter') as any)._data.features,
      ]
      setNewFeatures(feature);
    }
  }, [map])



  for (const layerName in hazard) {

    let title = '';
    let iconInfo: IconInfoProps = {};

    let hazardData = hazard[layerName];
    let depth = null
    let durationText = ''

    if (layerName === '高潮浸水想定区域図（想定最大規模）') {
      depth = hazardData[0]?.suisin
      depth = roundNumber(depth, 10)

      durationText = getDurationText(hazard, '高潮浸水想定区域図（浸水継続時間）')

      title = `浸水深${depth}m以上${durationText}`

    } else if (layerName === '洪水浸水想定区域図（想定最大規模）') {

      depth = hazardData[0]?.depth
      depth = roundNumber(depth, 10)

      durationText = getDurationText(hazard, '洪水浸水想定区域図（浸水継続時間）', 'm')
      title = `浸水深${depth}m以上${durationText}`

    } else if (layerName === '土砂災害警戒区域') {

      const isSpecialSedimentDisaster = hazardData.find(hazard => hazard && hazard['ゾーン'] === '土砂災害特別警戒区域')

      if (isSpecialSedimentDisaster) {
        title = '土砂災害特別警戒区域'
      } else {
        title = hazardData[0] && hazardData[0]['ゾーン']
      }

    } else if (
      layerName === '急傾斜地崩壊危険区域' ||
      layerName === '地すべり防止区域' ||
      layerName === '土石流危険渓流区域' ||
      layerName === '土石流危険渓流流域'
    ) {

      title = layerName

    } else if (
      layerName === '洪水浸水想定区域図（想定最大規模）' ||
      layerName === '洪水浸水想定区域図（氾濫流、家屋倒壊）' ||
      layerName === '洪水浸水想定区域図（河岸侵食、家屋倒壊）' ||
      layerName === '土石流危険渓流流域'
    ) {

      title = layerName

    } else if (
      layerName === '高潮浸水想定区域図（想定最大規模）'
    ) {
      title = layerName

      // スキップするレイヤー
    } else if (
      layerName === '高潮浸水想定区域図（浸水継続時間）' ||
      layerName === '洪水浸水想定区域図（計画規模）' ||
      layerName === '洪水浸水想定区域図（浸水継続時間）' ||
      layerName === '土石流危険渓流主流路'
    ) {
      continue;
    }

    //@ts-ignore
    iconInfo = hazardCategory[layerName]

    if (!iconInfo) {
      continue;
    }

    innerContents.push(
      <div key={layerName} className="popup-section">
        <div className="popup-icon" style={{ borderColor: iconInfo.color }}>
          <div className="popup-icon-inner">
            <img src={`${iconInfo.icon}`} alt={iconInfo.iconTitle} />
            <p>{iconInfo.iconTitle}</p>
          </div>
        </div>
        <div className="popup-text">
          <h3 className="popup-text-title">{text2BreakTag(title)}</h3>
        </div>
      </div>)
  }

  const sortedFacilities = useMemo(() => {

    const result: Facility[] = []
    if(facilities) {
      // facilities から type が fiware のものを除外
      const filteredFacilities = facilities.filter((facility) => {
        return facility.type !== 'fiware' || facility.id === 'shelter'
      })
      for (const facility of filteredFacilities) {

        if (facility.inPopup) {
          let sortedFeatures;

          if(facility.id === 'shelter' && shelterData) {
            sortedFeatures = sortByDistance(popupLatLng, newFeatures)
          }else {
            sortedFeatures = sortByDistance(popupLatLng, facility.geojson.features)
          }
          facility.geojson.features = sortedFeatures
          result.push(facility)
        }
      }
      return result
    } else {
      return undefined
    }
  }, [facilities, newFeatures, popupLatLng, shelterData]);

  return <>
    {
      (!isFetchingPopupData && Array.isArray(sortedFacilities)) ? <>
        {
          innerContents.length === 0 ?
            <div className='popup-section-title no-data'>特別に注意が必要ではありませんが、災害時は注意を怠らないで下さい</div>
            :
            <>
              {/* <p className="warning-card">災害時に注意が必要な可能性があります。万一に備え、事前に避難所の確認などをしておきましょう</p> */}
              <div className='popup-section-title'>注意が必要な災害リスク</div>
              <div className="popup-section-container">
                {innerContents}
                <div className="popup-text-altitude">
                  {
                      altitude === undefined ?
                      '--' :
                      `標高: ${altitude === null || _isNaN(altitude)? '--' : altitude}m`
                  }
                  </div>
              </div>
            </>
        }
        <div className='popup-section-title'>近くの施設</div>
        {
          sortedFacilities.map(facility =>
            <div className='popup-section-container-facility' key={facility.id}>
              <div className='popup-section-facility-title'>
                <img src={facility.icon} alt={facility.title} />
                <div style={{ color: facility.color }}>{facility.title}</div>
              </div>
              <ul>
                {
                  // 上位3件までをループ
                  facility.geojson.features.slice(0, 3).map((feature: any, index: number) => {
                    return (
                      <li key={index} onClick={() => {

                        if (!map) {
                          return
                        }

                        setIsPopupOpen(false)

                        // 同じ座標に、複数のマーカーがある場合に、指定したマーカーが一番上に表示されるようにするため
                        map.moveLayer(facility.id)

                        map.flyTo({
                          center: feature.geometry.coordinates,
                          zoom: 16,
                          speed: 2
                        })

                        map.once('moveend', () => {

                          const lngLat = {
                            lng: feature.geometry.coordinates[0],
                            lat: feature.geometry.coordinates[1]
                          }

                          setIsPopupOpen(true)
                          setPopupLatLng(lngLat)
                          setPopupCategory(facility.id)
                          setShelter(feature.properties)
                        })

                      }}>
                        <span className='label'>{feature.properties.name}</span>
                        <span className='distance'>{Math.round(feature.distance).toLocaleString()}m</span>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          )
        }
      </>
        :
        <Loading />
    }
    <div className="popup-cta-container">
      <a className="popup-cta-button" href={"https://www.city.takamatsu.kagawa.jp/kurashi/kurashi/shobo/bosai_map/takamatsu_map/index.html"} target="_blank" rel="noopener noreferrer">高松市 防災情報サイト</a>
    </div>
  </>
}

export default PopupContentHazard;
