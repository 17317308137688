import { PopupHazardContentProps } from './Popup';
import { Loading } from './Loading';
import formatDate from '../utils/formatDate';
import sortDataByLabel from '../utils/sortDataByLabel';
import config from '../config.json';

const facilityData: Array<{ [key: string]: string }> = [
  {
    "id": "sedimentDisaster",
    "name": "土砂災害",
    "icon": "icons/icon-landslide.svg",
  },
  {
    "id": "flood_L2",
    "name": "洪水",
    "icon": "icons/icon-flood.svg",
  },
  {
    "id": "stormSurge",
    "name": "高潮",
    "icon": "icons/icon-tsunami.svg",
  }
]

const otherDataLabels: { [key: string]: { [key: string]: string } } = {
  "aed": {
    "name": "名称",
    "address": "住所",
    "installationPosition": "設置位置",
    "location": "緯度・経度",
    "telephoneNumber": "電話番号",
    "availableDate": "利用可能曜日",
    "startTime": "開始時間",
    "endTime": "終了時間",
    "availableDateNote": "利用可能時間特記事項",
    "equipmentForChildren": "小児対応設備の有無",
  },
  "hospital": {
    "address": "住所",
    "alternativeName": "医療機関の種類",
    "availableDate": "利用可能曜日",
    "availableDateNote": "利用可能時間特記事項",
    "city": "市区町村名",
    "corporateRepresentative": "法人の代表者",
    "establisher": "設立者",
    "openingDate": "開設年月日",
    "corporateName": "法人の名称",
    "corporateNumber": "法人番号",
    "endTime": "終了時間",
    "extensionNumber": "内線番号",
    "faxNumber": "FAX番号",
    "fullNameInKana": "名称_カナ",
    "identification": "NO",
    "katagaki": "方書",
    "location": "緯度,経度",
    "medicalCode": "医療機関コード",
    "medicalEndTime": "診療終了時間",
    "medicalStartTime": "診療開始時間",
    "medicalSubjects": "診療科目",
    "name": "名称",
    "nickname": "愛称",
    "note": "備考",
    "numberOfBeds": "病床数",
    "overtimeResponse": "時間外における対応",
    "pref": "都道府県名",
    "prefCode": "都道府県コード又は市区町村コード",
    "referenceObject": "Ｕ_Ｒ_Ｌ",
    "startTime": "開始時間",
    "telephoneNumber": "電話番号",
  },
  "fire-station": {
    "address": "住所",
    "location": "緯度,経度",
    "name": "名称",
    "note": "備考",
    "referenceObject": "Ｕ_Ｒ_Ｌ",
    "telephoneNumber": "電話番号",
  },
}

const waterLevelLabels: { [key: string]: string } = {
  "名称": "観測地点名",
  "水位_測定値": "水位",
  "測定日時": "測定日時",
  "警戒水位": "警戒水位",
  "危険水位": "危険水位",
  "冠水発生水位": "冠水発生水位",
  "水防団待機水位": "水防団待機水位",
  "はん濫注意水位": "はん濫注意水位",
  "避難判断水位": "避難判断水位",
  "はん濫危険水位": "はん濫危険水位",
}

const floodSituationLabels: { [key: string]: string } = {
  "name": "路線名",
  "address": "所在地",
  "geoCrosses": "交差施設",
  "status": "冠水状況",
  "dateIssued": "測定日時",
  "note": "備考"
}

const RainfallLabels: { [key: string]: string } = {
  "名称": "観測地点名",
  "降雨量_測定値": "降雨量",
  "測定日時": "測定日時",
};

const tideLevelLabels: { [key: string]: string } = {
  '名称': '観測地点名',
  '潮位_測定値': '潮位',
  '測定日時': '測定日時',
  '警戒潮位': '警戒潮位',
  '注意潮位': '注意潮位',
  '冠水発生潮位': '冠水発生潮位',
};

const PopupContentFacility: React.FC<PopupHazardContentProps> = (props) => {
  const {
    shelter,
    popupCategory,
    isFetchingPopupData,
    popupObj
  } = props

  let popupTitle;
  let popupIcon = '';
  let category = '';
  let customColor : 'purple'|'red'|'yellow'|'blue'|'gray'|'' = '';
  let fontColor= '';
  let otherTableData: any[] = [];
  let dataCategory: string | null;
  const COLOR_CODE: { [key: string]: string} = {
    'purple': '#9747ff',
    'red': '#ff2020',
    'yellow': '#d8b404',
    'blue': '#008FDF',
    'gray': '#c9c9c9'
  }

  dataCategory = popupCategory;
  if(dataCategory === 'shelter' && shelter) {
    dataCategory = shelter.evacuationSpace === "○" ? "EvacuationShelter" : "TemporaryEvacuationShelter";
  }

  switch (dataCategory) {
    // 避難所（滞在可能）
    case 'EvacuationShelter':
      popupIcon = './icons/icon_evacuationSpace.svg';
      popupTitle = shelter? shelter['name'] : "";
      category = '避難所';
      break;
    // 緊急避難場所（緊急時一時的に避難する場所）
    case 'TemporaryEvacuationShelter':
      popupTitle = shelter? shelter['name'] : "";
      break;

    // 冠水状況
    case 'chikadou_custom_point':
      popupTitle = 'アンダーパス'
      otherTableData.push({
        key: floodSituationLabels['note'],
        value: '水位センサー無し'
      });
      break;
    case 'FloodSituation':
      for (const key in shelter) {
        if (
          typeof dataCategory !== 'string' ||
          !shelter[key] === undefined ||
          floodSituationLabels[key] === undefined
        ) {
          continue;
        }

        try {
          const value = shelter[key]

          let valueText;
          let floodSituationClass:string = dataCategory;

          if (key === 'status') {
            valueText = value === '1' ? '冠水あり' : '冠水なし'
            const color = value === '1' ? 'red' : value === '' ? 'gray' : 'blue';
            customColor = color;
            floodSituationClass = dataCategory + `-${color}`;
          } else {

            if (key === 'dateIssued') {
              valueText = formatDate(value)
            } else {
              valueText = value
            }
          }

          // 名称はタイトルに反映する
          if (key === 'name') {
            popupTitle = valueText;
            continue;
          }

          otherTableData.push({
            key: floodSituationLabels[key],
            //@ts-ignore
            value: valueText,
          });

          // popupの枠の色を変更
          popupObj?.removeClassName(dataCategory);
          popupObj?.addClassName(floodSituationClass);

        } catch (error) {
          continue;
        }
      }
    break;

    // 水位
    case 'WaterLevel':
    case 'PrefectureWaterLevel':
      for (const key in shelter) {
        if (
          typeof dataCategory !== 'string' ||
          !shelter[key] === undefined ||
          waterLevelLabels[key] === undefined
        ) {
          continue;
        }

        try {
          const value = shelter[key]
          let valueText;
          if (
            key === 'はん濫危険水位' ||
            key === 'はん濫注意水位' ||
            key === '水位_測定値' ||
            key === '水防団待機水位' ||
            key === '避難判断水位' ||
            key === '冠水発生水位' ||
            key === '危険水位' ||
            key === '警戒水位'
          ) {
            valueText = `${value}m`
          } else {
            if (key === '測定日時') {
              valueText = formatDate(value)
            } else {
              valueText = value
            }
          }
          // 名称はタイトルに反映する
          if (key === '名称') {
            popupTitle = valueText + (dataCategory === 'PrefectureWaterLevel' ? '（県）':'');
            continue;
          }
          otherTableData.push({
            key: waterLevelLabels[key],
            //@ts-ignore
            value: valueText,
          });
        } catch (error) {
          continue;
        }
      }
      // waterLevelLabels の順番で、otherTableData　を並べ替え
      otherTableData = sortDataByLabel(otherTableData, waterLevelLabels);
      break;

    // 降雨量
    case 'Rainfall':
      for (const key in shelter) {
        // 取得したいキーじゃなければとばす
        if ( typeof dataCategory !== 'string' || !shelter[key] === undefined || RainfallLabels[key] === undefined ) {
          continue;
        }

        try {
          const value = shelter[key]
          let valueText;
          if ( key === '降雨量_測定値' ) {
            valueText = `${value}mm`
          } else {
            if (key === '測定日時') {
              valueText = formatDate(value)
            } else {
              valueText = value
            }
          }

          // 名称はタイトルに反映する
          if (key === '名称') {
            popupTitle = valueText + '（県）';
            continue;
          }

          otherTableData.push({
            key: RainfallLabels[key],
            //@ts-ignore
            value: valueText,
          });

        } catch (error) {
          continue;
        }
      }
      break;

    case 'TideLevel':
    case 'PrefectureTideLevel':
      for (const key in shelter) {

        // 取得したいキーじゃなければとばす
        if ( typeof dataCategory !== 'string' || !shelter[key] === undefined || tideLevelLabels[key] === undefined ) {
          continue;
        }

        try {
          const value = shelter[key]
          let valueText;
          if ( key === '潮位_測定値' || key === '冠水発生潮位' || key === '警戒潮位' || key === '注意潮位' ) {
            valueText = `${value}m`
          } else {
            if (key === '測定日時') {
              valueText = formatDate(value)
            } else {
              valueText = value
            }
          }

          // 名称はタイトルに反映する
          if (key === '名称') {
            popupTitle = valueText + (dataCategory === 'PrefectureTideLevel' ? '（県）':'');
            continue;
          }

          otherTableData.push({
            key: tideLevelLabels[key],
            //@ts-ignore
            value: valueText,
          });

        } catch (error) {
          continue;
        }
      }
      // tideLevelLabels の順番で、otherTableData　を並べ替え
      otherTableData = sortDataByLabel(otherTableData, tideLevelLabels);
      break;

    // 避難所以外の施設情報
    default:
      popupTitle = shelter?.name;
      for (const key in shelter) {
        if (
          key === '#property' ||
          key === 'name' ||
          key === 'prefCode' ||
          key === 'nickname' ||
          key === 'identification' ||
          key === 'pref' ||
          key === 'referenceObject' ||
          key === 'location' ||
          key === 'fullNameInKana' ||
          key === 'alternativeName' ||
          key === 'katagaki' ||
          key === 'extensionNumber' ||
          key === 'medicalCode' ||
          key === 'overtimeResponse'
        ) {
          continue;
        }
        if (
          typeof dataCategory !== 'string' ||
          otherDataLabels[dataCategory] === undefined ||
          !shelter[key]
        ) {
          continue;
        };
        otherTableData.push({
          key: otherDataLabels[dataCategory][key] ? otherDataLabels[dataCategory][key] : key,
          value: shelter[key],
        });
      }
  }

  const selectedFacilityConfig = config.data.find((facility) => {
    if (facility.type === 'fiware') {
      return facility.sources.find((source) => source === dataCategory)
    } else {
      return facility.id === dataCategory
    }
  });

  if( selectedFacilityConfig ) {
    if ( customColor !== '' ) {
      popupIcon = selectedFacilityConfig.icon.replace('.svg', '') + '-' + customColor + '.svg';
      fontColor = COLOR_CODE[customColor];
    } else {
      popupIcon = popupIcon === '' ? selectedFacilityConfig.icon:popupIcon;
      fontColor = selectedFacilityConfig.color;
    }
    category = category === '' ? selectedFacilityConfig.title: category;
  }

  return <>
    {
      (shelter && !isFetchingPopupData) ?
        <>
          <div className="popup-section">
            <div className="popup-text">
              {
                selectedFacilityConfig &&
                <>
                  <div className="popup-text-label" style={{ color: fontColor }}>
                    <img className="popup-text-icon" src={popupIcon} alt={selectedFacilityConfig?.title} />
                    <div className="popup-text-category">{category}</div>
                  </div>
                </>
              }
              <h3 className="popup-text-title">{popupTitle}</h3>
            </div>
            {
              dataCategory === "EvacuationShelter" ||  dataCategory === "TemporaryEvacuationShelter" ?
                <>
                  <table className="table-shelter">
                    <tbody>
                      {
                        facilityData.map((facility, index) => {
                          const isLast = index === facilityData.length - 1;

                          return <tr key={facility.id}>
                            <td className={isLast ? 'last-child' : ''}>
                              <img src={facility.icon} alt={facility.name} width="55px" height="33px" />
                            </td>
                            <td className={isLast ? 'last-child' : ''}>{facility.name}</td>
                            <td className={isLast ? 'last-child' : ''}>{shelter[facility.id]}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                  <p className='note'>※ △は上層階又は一部の棟が使用可能</p>
                  <table className='table-shelter-meta'>
                    <tbody>
                      {
                        shelter.address ? <>
                          <tr>
                            <th>住所</th>
                            <td>
                              <p className="popup-text-content">{shelter['address']}</p>
                            </td>
                          </tr>
                        </>
                        :
                        <></>
                      }
                      {
                        shelter.elevation ? <>
                          <tr>
                            <th>標高</th>
                            <td>
                              <p className="popup-text-content">{shelter.elevation}m</p>
                            </td>
                          </tr>
                        </>
                        :
                        <></>
                      }
                      {
                        shelter.capacity ? <>
                          <tr>
                            <th>収容人数</th>
                            <td>
                              <p className="popup-text-content">{shelter.capacity}人</p>
                            </td>
                          </tr>
                        </>
                        :
                        <></>
                      }
                      { dataCategory === "EvacuationShelter" &&
                        <>
                        <tr>
                          <th>避難世帯数</th>
                          <td>
                            <p className="popup-text-content">{shelter["避難世帯数"] === "" ? 0 : shelter["避難世帯数"]}世帯</p>
                          </td>
                        </tr>
                        <tr>
                          <th>避難人数合計</th>
                          <td>
                            <p className="popup-text-content">{shelter["避難人数合計"] === "" ? 0 : shelter["避難人数合計"]}人</p>
                          </td>
                        </tr>
                        <tr>
                          <th>避難人数女</th>
                          <td>
                            <p className="popup-text-content">{shelter["避難人数女"] === "" ? 0 : shelter["避難人数女"]}人</p>
                          </td>
                        </tr>
                        <tr>
                          <th>避難人数男</th>
                          <td>
                            <p className="popup-text-content">{shelter["避難人数男"] === "" ? 0 : shelter["避難人数男"]}人</p>
                          </td>
                        </tr>
                        <tr>
                          <th>開設状況</th>
                          <td>
                            <p className="popup-text-content">{!shelter["開設状況"] || shelter["開設状況"] === "NOT_OPEN" ? "未開設" : "開設中"}</p>
                          </td>
                        </tr>
                        </>
                      }
                    </tbody>
                  </table>
                </>
                :
                // それ以外の場合
                <>
                  <table>
                    <tbody>
                      {
                        otherTableData.map((data, index) => {
                          return <tr key={data.key}>
                            <th>{`${data.key}`}</th><td>{`${data.value}`}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </>
            }
          </div>
        </>
        :
        <Loading />
    }
  </>

}

export default PopupContentFacility;
