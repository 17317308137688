const queryDuration = (data: any, key: string) => {

  if (data) {
    return Object.entries(data).find((item) => item[0] === key)
  } else {
    return []
  }
}

export const getDurationText = (hazard: any, key: string, unit?: string) => {

  let duration = null;
  let result = queryDuration(hazard, key)
  //@ts-ignore
  duration = result && result[1][0]?.keizoku

  if (unit === 'm') {
    duration = Math.round(duration / 60) // 単位を分に変換
  }

  return duration ? `\n継続時間${duration}時間以上` : ''
}
