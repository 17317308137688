import tilebelt from '@mapbox/tilebelt'
import { VectorTile } from 'mapbox-vector-tile'
import * as turf from '@turf/turf'

const HAZARD_SOURCE_URL = 'https://tileserver.geolonia.com/takamatsu-hazard_v3/tiles.json?key=8a9a6e7bf2ce43e188100aca3d78489a'

let sourceCache: { tiles: string[] } | null = null
let tileCache: { [xyz: string]: VectorTile } = {}

const LAYER_MAX_ZOOM: { [layerName: string]: number } = {
  急傾斜地崩壊危険箇所: 11,
  急傾斜地崩壊危険区域: 12,
  地すべり防止区域: 12,
  地すべり危険箇所: 10,
  土石流危険渓流区域: 11,
  土石流危険渓流流域: 10,
  土砂災害警戒区域: 13,
  "洪水浸水想定区域図（想定最大規模）": 14,
  "洪水浸水想定区域図（計画規模）": 14,
  "洪水浸水想定区域図（浸水継続時間）": 14,
  "洪水浸水想定区域図（氾濫流、家屋倒壊）": 11,
  "洪水浸水想定区域図（河岸侵食、家屋倒壊）": 12,
  "高潮浸水想定区域図（想定最大規模）": 14,
  "高潮浸水想定区域図（浸水継続時間）": 14,
}
// for v3
for (const layer in LAYER_MAX_ZOOM) {
  LAYER_MAX_ZOOM[layer] = 20
}

export const takamatsuHazardReverseGeocode = async (lng: number, lat: number) => {

  if(!sourceCache) {
    const resp = await fetch(HAZARD_SOURCE_URL)
    sourceCache = await resp.json()
  }

  const zooms = Object.values(LAYER_MAX_ZOOM).reduce<number[]>((prev, z) => {
    if(prev.indexOf(z) === -1) {
      prev.push(z)
    }
    return prev
  }, [])
  const xyzList: string[][] = zooms.map(zoom => tilebelt.pointToTile(lng, lat, zoom).map(num => num.toString()))

  const tiles: VectorTile[] = await Promise.all(xyzList.map(async ([x, y, z]) => {
    const key = `${z}/${x}/${y}`;
    if(tileCache[key]) {
      return tileCache[key]
    }

    const tileUrl = sourceCache!.tiles[0]
        .replace('{x}', x)
        .replace('{y}', y)
        .replace('{z}', z)

      const tileResp = await fetch(tileUrl)
      const tile = new VectorTile(new Uint8Array(await tileResp.arrayBuffer()))

      // 不要なレイヤーを削除
      for (const layerName in tile.layers) {
        const layerMaxZoom = LAYER_MAX_ZOOM[layerName]
        if(!key.startsWith(`${layerMaxZoom}/`)) {
          delete tile.layers[layerName]
        }
      }
      // @ts-ignore
      tile.__index = [x, y, z]
      tileCache[key] = tile
      return tile;
  }));

  const targetLayers = Object.keys(LAYER_MAX_ZOOM)
  const point = turf.point([lng, lat])

  const result: { [layerName: string]: GeoJSON.GeoJsonProperties[] } = {}

  for (const layerName of targetLayers) {
    const targetTile = tiles.find(tile => layerName in tile.layers)
    if(targetTile) {
      const layer = targetTile.layers[layerName]
      // @ts-ignore
      const [x, y, z] = targetTile.__index
      for (let index = 0; index < layer.length; index++) {
        const feature = layer.feature(index).toGeoJSON(x, y, z) as GeoJSON.Feature<GeoJSON.Polygon>;

        if(feature.geometry.coordinates[0].length < 4) continue
        const polygon = turf.polygon(feature.geometry.coordinates)
        if(turf.booleanPointInPolygon(point, polygon)) {
          if(!result[layerName]) {
            result[layerName] = []
          }
          result[layerName].push(feature.properties)
        // NOTE: 土砂災害警戒区域の場合、ゾーン別に2種類入っているので、全部返す
        if(layerName !== '土砂災害警戒区域') {
            break
          }
        }
      }
    }
  }
  return result
}
