import * as turf from '@turf/turf'

const sortByDistance = (point: any, features: any) => {

  const from = turf.point([point.lng, point.lat]);

  const featuresWithDistance = [];

  for (let i = 0; i < features.length; i++) {
    const feature = features[i];
    if (
      typeof feature.geometry.coordinates[0] !== 'number' ||
      typeof feature.geometry.coordinates[1] !== 'number' ||
      Number.isNaN(feature.geometry.coordinates[0]) ||
      Number.isNaN(feature.geometry.coordinates[1])
    ) {
      continue
    }
    const to = turf.point([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
    const distance = turf.distance(from, to, {units: 'meters' as 'meters'});
    featuresWithDistance.push({ ...feature, distance })
  }

  const sortedFeatures = featuresWithDistance.sort((a:any, b:any) => {
    if(typeof a.distance !== 'number' || Number.isNaN(a.distance)) {
      return 1
    } else if (typeof b.distance !== 'number' || Number.isNaN(b.distance)) {
      return -1
    } else {
      return a.distance - b.distance
    }
  })

  return sortedFeatures
}

export default sortByDistance
